<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm :title="formTitle"
                       in-progress-message="Updating Assignment, Please wait..."
                       success-message="Assignment Added Successfully"
                       :action-text="formTitle"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="addEditAssignment"
                       @close="$refs.sideBar.emitToggle()">
      <div>
        <DoodleInput field-name="title" label-text="Title" type="text"
                     v-model="doodleForm.title" :model="doodleForm.title"
                     rule="required|min:3|max:255" place-holder="Title"/>
        <DoodleTextArea field-name="description" label-text="Description"
                     :model="doodleForm.description"
                     v-model="doodleForm.description" rule="required|min:2|max:10000" rows="5"/>
        <div class="columns">
          <div class="column is-6">
            <DoodleSelect label-text="Default Language" :model="doodleForm.defaultLanguage"
                          v-model="doodleForm.defaultLanguage" field-name="Language"
            :list="languages" display-item="false" display-property="displayName" @change="onLanguageChange"/>
          </div>
          <div class="column is-6" v-if="showIndexBox">
            <DoodleSelect label-text="Version" v-model="doodleForm.versionIndex" :model="doodleForm.versionIndex"
                          field-name="Version" :list="doodleForm.defaultLanguage.versions" display-item="true"/>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <DoodleSelect label-text="Result Type" :model="doodleForm.resultType"
                          v-model="doodleForm.resultType" field-name="Result Type"
                          :list="resultTypes" display-item="false" display-property="type" @change="onResultTypeChange"/>
          </div>
        </div>
        <div v-if="isClone">
          <label class="checkbox has-text-weight-bold margin-top-10px">
            <input type="checkbox" v-model="doodleForm.cloneStudents">
            Clone Students
          </label>
        </div>
        <div v-if="isClone">
          <label class="checkbox has-text-weight-bold margin-top-10px">
            <input type="checkbox" v-model="doodleForm.cloneStaffs">
            Clone Staffs
          </label>
        </div>
        <div>
          <label class="checkbox has-text-weight-bold margin-top-10px">
            <input type="checkbox" v-model="doodleForm.autoCorrect" @change="onAutoCorrectChange">
            Auto Correct
          </label>
        </div>
        <div>
          <label class="checkbox has-text-weight-bold margin-top-10px" v-if="doodleForm.autoCorrect">
            <input type="checkbox" v-model="doodleForm.autoScore" @change="onAutoScoreChange">
            Auto Score/Mark
          </label>
        </div>
        <div class="margin-top-10px">
          <label class="checkbox has-text-weight-semibold" v-if="doodleForm.autoScore">
            <input type="checkbox" v-model="doodleForm.enableNegativeMark">
            Enable Negative Marking
          </label>
        </div>
        <div class="margin-top-10px" v-if="showDiscloseSection">
          <DoodleSelect label-text="Disclose Result" :model="doodleForm.disclose"
                        v-model="doodleForm.disclose" field-name="Disclose Result"
                        :list="discloseTypes" display-item="false" display-property="option" @change="onDiscloseChange"/>
          <div class="auto-score-note">Note: Auto Score/Mark and Disclose is only applicable for systematically validatable questions only. Open Ended questions and questions excluded from auto correction will not be auto scored.</div>
          <div class="auto-score-note has-text-danger" v-if="showCloneWarning">Warning: You have selected auto disclose at submission, so open ended question will be ignored when cloning the assignment.</div>
        </div>
        <div class="margin-top-10px">
          <label class="checkbox has-text-weight-semibold" v-if="doodleForm.autoScore && doodleForm.disclose===discloseTypes[1]">
            <input type="checkbox" v-model="doodleForm.isPublic">
            Is Public Assignment
          </label>
        </div>
      </div>
      <template v-slot:successMessage>
        <div class="has-text-centered">
          <div class="register-thanks" v-if="isAdd">
            <div>Assignment added!</div>
            <div class="has-text-weight-semibold margin-top-10px is-underlined"><a @click="gotoAssignment">Goto New Assignment</a></div>
          </div>
          <div class="register-thanks" v-else-if="isEdit">
            Assignment Updated!
          </div>
          <div class="register-thanks" v-else-if="isClone">
            <div>Assignment Cloned!</div>
            <div class="has-text-weight-semibold margin-top-10px is-underlined"><a @click="gotoAssignment">Goto New Assignment</a></div>
          </div>
        </div>
      </template>
    </DoodleGeneralForm>
  </SideBar>
</template>

<script>
import formMixin from '../../../assets/javascript/form-mixin'
import langListMixin from '../../../assets/javascript/language-list-mixin'
import DoodleInput from '../../Utils/DoodleInput'
import DoodleGeneralForm from '../../Utils/DoodleGeneralForm'
import SideBar from '../../Utils/SideBar'
import { eventBus } from '../../../assets/javascript/event-bus'
import { GURU_CONSTANTS, GURU_EVENTS, GURU_FORM_STATUS } from '../../../assets/javascript/constants'
import DoodleTextArea from '../../Utils/DoodleTextArea'
import DoodleSelect from '../../Utils/DoodleSelect'

export default {
  name: 'guruAddEditAssignment',
  mixins: [formMixin, langListMixin],
  inject: ['$validator'],
  components: { DoodleSelect, DoodleTextArea, SideBar, DoodleGeneralForm, DoodleInput },
  props: ['mode', 'instituteCode', 'email', 'initData'],
  data: function () {
    return {
      doodleForm: {
        insAssignmentId: null,
        title: null,
        description: null,
        defaultLanguage: {},
        versionIndex: null,
        autoCorrect: false,
        autoScore: false,
        enableNegativeMark: false,
        disclose: null,
        resultType: null,
        cloneStudents: false,
        cloneStaffs: false,
        isPublic: false
      },
      newAssignmentId: null,
      editMode: false,
      watchReady: false
    }
  },
  mounted () {
    if (this.isAdd) {
      this.doodleForm.defaultLanguage = this.languages[0]
      this.doodleForm.resultType = this.resultTypes[0]
      this.doodleForm.disclose = this.discloseTypes[0]
    } else {
      Object.assign(this.doodleForm, this.initData)
    }
    this.watchReady = true
  },
  methods: {
    addEditAssignment () {
      this.doodleForm.errorMessage = ''
      let url
      if (this.isAdd) {
        url = '/api/guru-api/assignment/addAssignment'
      } else if (this.isEdit) {
        url = '/api/guru-api/assignment/editAssignment'
      } else if (this.isClone) {
        url = '/api/guru-api/assignment/cloneAssignment'
      }

      this.executeAPI({
        url: url,
        data: {
          instituteCode: this.instituteCode,
          title: this.doodleForm.title,
          description: this.doodleForm.description,
          autoCorrect: this.doodleForm.autoCorrect,
          disclose: this.doodleForm.disclose.value,
          autoScore: this.doodleForm.autoScore,
          enableNegativeMark: this.doodleForm.enableNegativeMark,
          defaultLanguage: this.doodleForm.defaultLanguage.name,
          versionIndex: this.doodleForm.versionIndex,
          assignmentId: this.doodleForm.insAssignmentId,
          isPublic: this.doodleForm.isPublic,
          resultType: this.doodleForm.resultType.value,
          cloneStudents: this.doodleForm.cloneStudents,
          cloneStaffs: this.doodleForm.cloneStaffs
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          this.newAssignmentId = data.assignment.insAssignmentId
          if (this.isAdd) {
            eventBus.$emit(GURU_EVENTS.ADD_TO_ASSIGNMENTS, data.assignment)
          } else if (this.isEdit || this.isClone) {
            if (this.isClone) {
              data.assignment.startTime = null
              data.assignment.endTime = null
              this.gotoAssignment()
            }
            eventBus.$emit(GURU_EVENTS.UPDATE_IN_ASSIGNMENT, data.assignment)
          }
        },
        jdaEvent: (this.isAdd) ? 'guru-add-assignment' : 'guru-update-assignment',
        jdaLabel: ''
      })
    },
    onLanguageChange () {
      if (this.doodleForm.defaultLanguage.versions.length > 0) {
        this.doodleForm.versionIndex = 0
      }
    },
    gotoAssignment () {
      this.$router.push('/guru/ins/' + this.$route.params.instituteCode + '/assignment/' + this.newAssignmentId)
    },
    resetDisclose () {
      if (this.doodleForm.disclose === this.discloseTypes[1] && this.doodleForm.autoScore === false && this.doodleForm.resultType !== this.resultTypes[2]) {
        this.doodleForm.disclose = this.discloseTypes[0]
      }
      this.onDiscloseChange()
    },
    onAutoCorrectChange () {
      this.doodleForm.autoScore = false
    },
    onAutoScoreChange () {
      this.doodleForm.enableNegativeMark = false
      this.resetDisclose()
    },
    onResultTypeChange () {
      this.resetDisclose()
    },
    onDiscloseChange () {
      if (this.doodleForm.disclose !== this.discloseTypes[1]) {
        this.doodleForm.isPublic = false
      }
    }
  },
  computed: {
    formTitle () {
      let titlePrefix = ''

      if (this.localMode === GURU_FORM_STATUS.ADD) {
        titlePrefix = 'Create'
      } else if (this.localMode === GURU_FORM_STATUS.EDIT) {
        titlePrefix = 'Update'
      } else if (this.localMode === GURU_FORM_STATUS.CLONE) {
        titlePrefix = 'Clone'
      }

      return titlePrefix + ' Assignment'
    },
    localMode () {
      if (this.editMode) {
        return GURU_FORM_STATUS.EDIT
      }

      return this.mode
    },

    isAdd () {
      return this.localMode === GURU_FORM_STATUS.ADD
    },
    isEdit () {
      return this.localMode === GURU_FORM_STATUS.EDIT
    },
    isClone () {
      return this.localMode === GURU_FORM_STATUS.CLONE
    },
    showIndexBox () {
      return this.doodleForm.defaultLanguage.versions &&
        this.doodleForm.defaultLanguage.versions.length > 0 && this.doodleForm.versionIndex != null
    },
    resultTypes () {
      return GURU_CONSTANTS.RESULT_TYPES
    },
    discloseTypes () {
      return GURU_CONSTANTS.DISCLOSE_OPTIONS
    },
    showCloneWarning () {
      return this.isClone && this.doodleForm.disclose === this.discloseTypes[1]
    },
    showDiscloseSection () {
      return (this.doodleForm.autoCorrect && this.doodleForm.resultType === this.resultTypes[2]) ||
        (this.doodleForm.autoCorrect && this.doodleForm.autoScore)
    }
  }
}
</script>

<style scoped lang="scss">
  .auto-score-note {
    font-size: 0.85em;
  }
</style>
